<template>
  <b-overlay
    :show="showOverlay"
    rounded="sm"
    spinner-variant="primary"
    spinner-type="grow"
  >
    <div class="auth-wrapper auth-v2">
      <b-row class="auth-inner m-0">

        <!-- Brand logo-->
        <b-link class="brand-logo">
          <b-img
            :src="appLogoImage"
            alt="logo"
          />
          <h2 class="brand-text text-primary ml-1">
            {{ appName }}
          </h2>
        </b-link>
        <!-- /Brand logo-->

        <!-- Left Text-->
        <b-col
          lg="8"
          class="d-none d-lg-flex align-items-center auth-left-bg p-5"
        >
          <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
            <b-img
              fluid
              :src="imgUrl"
              alt="Forgot Password"
            />
          </div>
        </b-col>
        <!-- /Left Text-->

        <!-- Login-->
        <b-col
          lg="4"
          class="d-flex align-items-center auth-bg px-2 p-lg-5"
        >
          <b-col
            sm="8"
            md="6"
            lg="12"
            class="px-xl-2 mx-auto"
          >
            <b-card-title
              class="mb-1 font-weight-bold"
              title-tag="h2"
            >
              Forgot Password?
            </b-card-title>
            <b-card-text class="mb-2">
              Can't log in? We'll send a link to reset the password to your email address:
            </b-card-text>
            <!-- form -->
            <validation-observer
              ref="forgotPasswordForm"
              #default="{invalid}"
            >
              <b-form
                class="auth-login-form mt-2"
                @submit.prevent="forgotPassword"
              >
                <!-- email -->
                <b-form-group
                  label="Email"
                  label-for="login-email"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    vid="email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="login-email"
                      v-model="userEmail"
                      :state="errors.length > 0 ? false:null"
                      name="login-email"
                      placeholder="john@example.com"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- submit buttons -->
                <b-button
                  type="submit"
                  variant="primary"
                  block
                  :disabled="invalid"
                  name="forgotPassword"
                >
                  <span
                    v-if="showSpinner"
                    class="spinner-border spinner-border-sm spinner-in-button"
                    role="status"
                    aria-hidden="true"
                  />
                  Request reset password
                </b-button>
                <small
                  v-if="loginError"
                  class="text-danger"
                >Error: Failed. Please check details and try again</small>
              </b-form>
            </validation-observer>
          </b-col>
        </b-col>
        <!-- /Login-->
      </b-row>
    </div>
  </b-overlay>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BCardText, BCardTitle, BImg, BForm, BButton, VBTooltip, BOverlay,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import { $themeConfig } from '@themeConfig'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BOverlay,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      showSpinner: false,
      sideImg: require('@/assets/images/pages/login-v2.svg'),

      // validation rules
      required,
      email,
      loginError: false,
      oldPassword: null,
      showOverlay: false,
    }
  },
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app
    return {
      appName,
      appLogoImage,
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    forgotPassword() {
      this.loginError = false
      this.$refs.forgotPasswordForm.validate().then(success => {
        if (success) {
          this.showSpinner = true

          this.$http.post('/auth/password/forgot', {
            userName: this.userEmail,
          })
            .then(() => {
              // Redirect to login
              this.$router.replace({ name: 'auth-forgot-password-success' })
            })
            .catch(error => {
              this.showOverlay = false

              this.showSpinner = false
              console.log('error:', error)
              this.loginError = true
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
